<template>
	<div class="back-top" :class="isVisibility ? 'animate__animated animate__zoomIn' : 'animate__animated animate__zoomOut'" :style="[backTopStyle]" @click="backTopClick">
		<h-img class="icon" :src="require('@/assets/054.png')"></h-img>
	</div>
</template>

<script>
export default {
	name: 'backtop',
	data() {
		return {
			backTopStyle: {},
			isVisibility: false
		};
	},
	mounted() {
		this.$bus.$on('windowOnscroll', data => {
			this.backTopPosition();
		});
	},
	methods: {
		backTopPosition() {
			if (window.scrollY > window.screen.height / 2) {
				this.isVisibility = true;
			} else {
				this.isVisibility = false;
				return;
			}

			if (window.screen.width > 1200) {
				let gap = window.screen.width - 1200 - 80 * 2;
				if (gap > 0) {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '50px',
						zIndex: 9999,
						display: 'block',
						right: (gap / 2 - 100) / 2 + 'px',
						width: '80px',
						height: '80px',
						opacity: 1
					};
				} else {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '50px',
						zIndex: 9999,
						display: 'block',
						right: '12px',
						width: '80px',
						height: '80px',
						opacity: 1
					};
				}
			} else {
				let gap = window.screen.width - 1200 - 40 * 2;
				if (gap > 0) {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '180px',
						zIndex: 9999,
						display: 'block',
						right: (gap / 2 - 40) / 2 + 'px',
						width: '40px',
						height: '40px',
						opacity: 1
					};
				} else {
					this.backTopStyle = {
						position: 'fixed',
						bottom: '180px',
						zIndex: 9999,
						display: 'block',
						right: '12px',
						width: '40px',
						height: '40px',
						opacity: 1
					};
				}
			}
		},
		backTopClick() {
			this.$global.backTop();
		}
	}
};
</script>

<style lang="scss" scoped>
	.back-top {
		display: flex;
		border-radius: 50%;
		background-color: transparent;
		cursor: pointer;
		.icon {
			width: 100%;
			height: 100%;
		}
	}
</style>
