import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
// import 'lib-flexible/flexible.js'
Vue.mixin(mixins)
// 配置element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
Vue.config.productionTip = false

import hImg from '@/components/h-img/h-img.vue'
Vue.component('h-img',hImg)

// 挂载全局变量
import global from './global.js'
Vue.prototype.$global = global

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
  	Vue.prototype.$bus = this //安装全局事件总线
  }
}).$mount('#app')
