import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

import headerOne from '@/components/header/headerOne/headerOne.vue'
import footerOne from '@/components/footer/footerOne/footerOne.vue'

const routes = [
	// 首页
	{
		path: '/home',
		name: 'home',
		component: () => import('../views/home/home.vue')
	},
	// 个人业务
	{
		path: '/personal/:name',
		name: 'personal',
		props: true,
		component: () => import('../views/geRenYeWu/geRenYeWu.vue')
	},
	// 商户业务
	{
		path: '/business/:name',
		name: 'business',
		props: true,
		component: () => import('../views/shangHuYeWu/shangHuYeWu.vue')
	},
	//新闻中心
	{
		path: '/newsCenter',
		name: 'newsCenter',
		component: () => import('../views/xinWenZhongXin/xinWenZhongXin.vue')
	},
	//新闻详情
	{
		path: '/newsDetail/:id',
		name: 'newsDetail',
		props: true,
		component: () => import('../views/xinWenZhongXin/xinWenXiangQing/xinWenXiangQing.vue')
	},
	//关于我们
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: () => import('../views/guanYuWoMen/guanYuWoMen.vue')
	},
	
]


const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
})

// 前置路由守卫
router.beforeEach((to, from, next) => {
	console.log("to: ", to);
	console.log("from: ", from);
	if (to.path == '/') {
		next('/home')
	} else {
		next()
	}
})

export default router
