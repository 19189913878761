<template>
	<div class="header" id="header">
		<!-- 移动端 -->
		<div v-if="isMobile" class="device-mobile">
			<div class="fixed-box" :style="{ height: isShowMobileMenu ? '100vh' : 'auto' }">
				<div class="fixed-box-wrapper">
					<div class="menu-box" @click="openMobileMenu">
						<h-img v-if="isShowMobileMenu" class="pic-024" :src="require('@/assets/025.png')"></h-img>
						<h-img v-else class="pic-024" :src="require('@/assets/024.png')"></h-img>
					</div>
					<div class="logo-box" @click="$router.push('/home')">
						<h-img class="pic-002" style="margin-right: 20px;" :src="require('@/assets/002.png')"></h-img>
						<h-img class="pic-002" :src="configListAll.website_logo"></h-img>
					</div>
<!-- 					<h-img class="pic-002" :src="require('@/assets/002.png')" @click="$router.push('/home')"></h-img> -->
					<h-img class="pic-024" :src="require('@/assets/024.png')" style="visibility: hidden;"></h-img>
				</div>
				<template v-if="isShowMobileMenu">
					<div class="menu">
						<el-collapse accordion>
							<el-collapse-item class="yiji-el-collapse-item" v-for="(yiJiitem, yiJiindex) in navList" :key="yiJiindex" >
								<div class="yiji-title" slot="title" @click="yiJiMenuClick(yiJiitem, yiJiindex)">{{ yiJiitem.name }}</div>
								<el-collapse accordion v-if="yiJiitem.children.length">
									<el-collapse-item
										class="erji-el-collapse-item"
										v-if="erJiitem.children && erJiitem.children.length"
										v-for="(erJiitem, erJiindex) in yiJiitem.children"
										:key="erJiindex"
									>
										<div class="erji-title" slot="title">{{ erJiitem.name }}</div>
										<el-collapse accordion v-if="erJiitem.children && erJiitem.children.length">
											<div class="sanji-item" v-for="(sanJiitem, sanJiindex) in erJiitem.children" :key="sanJiindex" @click="sanJiClick(sanJiitem)">{{ sanJiitem.name }}</div>
										</el-collapse>
									</el-collapse-item>
									<div class="erji-item" v-else @click="erJiClick(erJiitem, erJiindex)">{{ erJiitem.name }}</div>
								</el-collapse>
							</el-collapse-item>
						</el-collapse>
					</div>
					<div class="mask" @click="mobileMaskClick"></div>
				</template>
			</div>
		</div>
		<!-- pc端 -->
		<div v-else class="device-pc">
			<div class="fixed-box">
				<div class="fixed-box-wrapper">
					<div class="logo-box">
						<h-img class="logo" style="margin-right: 20px;" :src="require('@/assets/002.png')" @click="$router.push('/home')"></h-img>
						<h-img class="logo" :src="configListAll.website_logo" @click="$router.push('/home')"></h-img>
					</div>
					<div class="first-level">
						<h-img class="pic-001" :src="require('@/assets/001.png')"></h-img>
						<div class="list">
							<div class="item" :class="index == yiJiIndex ? 'active' : 'normal'" v-for="(item, index) in navList" :key="index" @click="yiJiMenuClick(item, index)">
								{{ item.name }}
							</div>
						</div>
					</div>
				</div>
				<template v-if="yiJiIndex > -1">
					<div class="second-level" @mouseleave="hideThreeLevelView">
						<div class="second-level-wrapper-1">
							<div class="second-level-wrapper-2">
								<div class="padding" @mouseover="hideThreeLevelView"></div>
								<div
									class="item"
									v-for="(seconditem, secondindex) in navList[yiJiIndex].children"
									:key="secondindex"
									@mouseenter="onMouseenter(seconditem, secondindex)"
									@click="erJiClick(seconditem, secondindex)"
								>
									<img class="icon" :src="seconditem.src" alt="" />
									{{ seconditem.name }}
								</div>
								<div class="padding" @mouseover="hideThreeLevelView"></div>
							</div>
						</div>
						<div v-if="erJiIndex > -1 && navList[yiJiIndex].children[erJiIndex].children && navList[yiJiIndex].children[erJiIndex].children.length" class="three-level" @mouseleave="hideThreeLevelView">
							<div class="three-level-wrapper">
								<div
									class="item"
									v-for="(threeitem, threeindex) in navList[yiJiIndex].children[erJiIndex].children"
									:key="threeindex"
									@click="sanJiClick(threeitem)"
								>
									{{ threeitem.name }}
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="mask" v-if="yiJiIndex > -1" @click="pcMaskClick"></div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	name: 'headerOne',
	data() {
		return {
			navList: this.$global.navList_for_headerOne,
			yiJiIndex: -1,
			erJiIndex: -1,
			isShowMobileMenu: false
		};
	},
	computed: {
		...mapState(['configListAll'])
	},
	watch: {
		$route: {
			handler(val, oldval) {
				console.log(val, 'val'); //新路由信息
				console.log(oldval, 'oldval'); //老路由信息
				this.yiJiIndex = -1;
				this.erJiIndex = -1;
				this.isShowMobileMenu = false;
			},
			// 深度观察监听
			deep: true
		}
	},
	mounted() {},
	methods: {
		openMobileMenu() {
			this.isShowMobileMenu = !this.isShowMobileMenu;
		},
		mobileMaskClick() {
			this.isShowMobileMenu = false;
		},

		pcMaskClick() {
			this.yiJiIndex = -1;
		},
		yiJiMenuClick(item, index) {
			console.log("item: ",item);
			if (index == this.yiJiIndex) {
				this.yiJiIndex = -1;
			} else {
				this.yiJiIndex = index;
			}
		},
		onMouseenter(seconditem, secondindex) {
			this.erJiIndex = secondindex;
		},
		hideThreeLevelView() {
			this.erJiIndex = -1;
		},
		erJiClick(seconditem, secondindex) {
			console.log("seconditem: ",seconditem);
			if (!seconditem.children || !seconditem.children.length) {
				if (this.navList[this.yiJiIndex].name == '新闻中心' || this.navList[this.yiJiIndex].name == '关于我们') {
					if (this.$route.name != this.navList[this.yiJiIndex].routeName) {
						this.$store.commit('changeErJiMenuPath', seconditem.name)
						this.$router.push({ name: this.navList[this.yiJiIndex].routeName});
					} else {
						console.log("111: ",111);
						this.yiJiIndex = -1;
						this.isShowMobileMenu = false;
						this.$store.commit('changeErJiMenuPath', seconditem.name)
					}
				} else {
					if (this.$route.params.name != seconditem.name) {
						this.$router.push({ name: this.navList[this.yiJiIndex].routeName, params: { name: seconditem.name } });
					} else {
						console.log("222: ",222);
						this.yiJiIndex = -1;
						this.isShowMobileMenu = false;
						this.$global.backTop()
					}
				}
			} else {
				// this.$router.push({ name: this.navList[this.yiJiIndex].routeName, params: { name: this.navList[this.yiJiIndex].children[secondindex].children[0].name } });
			}
		},
		sanJiClick(threeitem) {
			if (this.$route.params.name != threeitem.name) {
				this.$router.push({ name: this.navList[this.yiJiIndex].routeName, params: { name: threeitem.name } });
			} else {
				this.yiJiIndex = -1;
				this.isShowMobileMenu = false;
				this.$global.backTop()
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import './headerOne.scss';
@import './headerOneMedia.scss';
</style>
