<template>
	<div id="app">
		<headerOne></headerOne>
		<template v-if="isMobile">
			<router-view :class="$route.name == 'personal' ? '' : 'app-body-pc'"></router-view>
		</template>
		<template v-else>
			<keep-alive><router-view :class="$route.name == 'personal' ? '' : 'app-body-mobile'"></router-view></keep-alive>
		</template>
		<footerOne></footerOne>
		<backTop></backTop>
	</div>
</template>
<script>
import headerOne from '@/components/header/headerOne/headerOne.vue';
import footerOne from '@/components/footer/footerOne/footerOne.vue';
import backTop from '@/components/backTop/backTop.vue';

export default {
	components: {
		headerOne,
		footerOne,
		backTop
	},
	data() {
		return {
			
		};
	},
	created() {
		// 监听页面尺寸
		window.addEventListener('resize', () => {this.$store.commit('changeScreenWidth')});

		// 初始化菜单
		this.$store.commit('changeMenuListAll', this.$global.navList_for_headerOne);

		// 获取网站信息
		this.$store.dispatch('changeConfigListAllAction');
		
		// 监听页面滚动
		window.onscroll = () => {
			// 发送滚动事件
			this.$bus.$emit('windowOnscroll')
			
			// 监听滚动到底部
			if (this.$global.reachBottom()) {
				this.$bus.$emit('reachBottom');
			}
		};
	},
	mounted() {
		// new WOW({
		// 	boxClass: 'wow',
		// 	animateClass: 'animated',
		// 	offset: 150,
		// 	mobile: true,
		// 	live: true
		// }).init();
	},
	methods: {
		
	}
};
</script>
<style lang="scss">
	@import '@/global.scss';
</style>
<style lang="scss" scoped>
.app-body-pc {
	flex: 1;
	min-height: calc(100vh - 90px);
}

.app-body-mobile {
	flex: 1;
	min-height: calc(100vh - 57px);
}
</style>
