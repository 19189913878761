<template>
	<div  class="footer-one" id="footer-one">
		<div v-if="isMobile" class="device-mobile">
			<div class="top-block">
				<h-img class="pic-020" :src="require('@/assets/020.png')" @click="$router.push('/home')"></h-img>
				<div class="item">
					<h-img class="icon" :src="configListAll.website_afficial"></h-img>
					<div class="txt">微信公众号</div>
				</div>
				<div class="item">
					<h-img class="icon" :src="configListAll.website_applet"></h-img>
					<div class="txt">微信小程序</div>
				</div>
				<div class="item">
					<h-img class="icon" :src="configListAll.website_weibo"></h-img>
					<div class="txt">微博</div>
				</div>
			</div>
			<div class="bottom-block">
				<div class="txt-2">© 中国银联版权所有</div>
				<div class="txt-3">沪ICP备07032180号-2</div>
				<div class="txt-4-box">
					<h-img class="pic-023" :src="require('@/assets/023.png')"></h-img>
					<div class="txt-4">沪公网安备31011502002335号</div>
				</div>
			</div>
		</div>
		<div v-else class="device-pc">
			<div class="top-block">
				<div class="wrap">
					<div class="left-block">
						<h-img class="logo" :src="configListAll.website_logo" @click="$router.push('/home')"></h-img>
<!-- 						<h-img class="logo" :src="require('@/assets/020.png')" @click="$router.push('/home')"></h-img> -->
					</div>
					<div class="nav-list">
						<div class="column" v-for="(item,index) in navList" :key="index">
							<div class="first">{{item.name}}</div>
							<div class="item" v-for="(childrenitem,childrenindex) in item.children" :key="childrenindex" @click="openPage(item,childrenitem)">{{childrenitem.name}}</div>
						</div>
					</div>
					<div class="right-block">
						<div class="item">
							<div class="pic-box">
								<h-img class="icon" :src="configListAll.website_afficial"></h-img>
							</div>
							<div class="txt">微信公众号</div>
						</div>
						<div class="item">
							<div class="pic-box">
								<h-img class="icon" :src="configListAll.website_applet"></h-img>
							</div>
							<div class="txt">微信小程序</div>
						</div>
						<div class="item">
							<div class="pic-box">
								<h-img class="icon" :src="configListAll.website_weibo"></h-img>
							</div>
							<div class="txt">微博</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-block">
				<div class="wrap">
					<div class="txt-1">网站使用条款</div>
					<div class="txt-2">{{configListAll.copyright}}</div>
					<div class="txt-4-box">
						<h-img class="pic-023" :src="require('@/assets/023.png')"></h-img>
						<div class="txt-4">{{configListAll.beian_code}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		name: 'footerOne',
		data() {
			return {
				navList: this.$global.navList_for_headerOne,
				
			}
		},
		computed: {
			...mapState(['configListAll'])
		},
		methods: {
			openPage(item,childrenitem) {
				if (!childrenitem.children || !childrenitem.children.length) {
					if (item.name == '新闻中心' || item.name == '关于我们') {
						if (this.$route.name != item.routeName) {
							this.$store.commit('changeErJiMenuPath', childrenitem.name)
							this.$router.push({ name: item.routeName});
						} else {
							this.$store.commit('changeErJiMenuPath', childrenitem.name)
							this.$global.backTop()
						}
					} else {
						if (this.$route.params.name != childrenitem.name) {
							this.$router.push({ name: item.routeName, params: { name: childrenitem.name } });
						} else {
							this.$global.backTop()
						}
					}
				} else {
					if (this.$route.params.name != childrenitem.children[0].name) {
						this.$router.push({ name: item.routeName, params: { name: childrenitem.children[0].name } });
					} else {
						this.$global.backTop()
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import './footerOne.scss';
	@import './footerOneMedia.scss';
</style>