<template>
	<img class="h-img" :src="srcComputed" @click="onclick">
</template>

<script>
	export default {
		name: 'h-img',
		props: ['src'],
		computed: {
			srcComputed() {
				// console.log("this.src: ",this.src);
				if (this.src) {
					if(this.src.startsWith('http') || !this.src.startsWith('/') ){
						return this.src
					} else {
						console.log(this.webImg);
						return this.webImg + this.src
					}
				} else {
					return ''
				}
				
			}
		},
		methods: {
			onclick() {
				this.$emit('click')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.h-img {
		&:hover {
			
		}
		// width: 100%;
	}
</style>