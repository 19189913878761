import axios from 'axios'
import {
  Notification
} from 'element-ui'
import store from '@/store'
import QS from 'qs'
import md5 from 'js-md5'
import router from '@/router/index'

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}`, // url = base url + request url
  
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout，
})

// request interceptor
service.interceptors.request.use(
  config => {
      CalcuMD5(QS.parse(config.data)).then(signMD5 => {
		 config.headers['ds'] = 'SIXUNYUN_YINLIAN'
      })
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // 统一定义状态码为200时，表示请求成功，其他请求失败
    // console.log(response,"13")
    if(!response.data.code){
      return Promise.resolve(response.data)
    }

      switch (response.data.code){
        //成功
        case 200:
          return Promise.resolve(response.data)
        case 401:
          // 401 重新登录
          return Promise.reject(response.data)
        case 400:
          // 400 状态错误
          // console.log(response)
          Notification.error({
            message: response.data.msg
          })
          return response
        default:
          //失败
          Notification.error({
            message:  response.data.msg
          })
          return Promise.reject(response.data);
      }
  },
  error => {
    const res = error.response.data
    const status = error.response.status
    switch (status) {
      case 500:
        if (res.message) {
          Notification.error({
            message: res.message
          })
        }
        return Promise.reject(new Error(res.message || 'Error'))
      case 401:
        // 401 重新登录
        return Promise.reject(error)
      case 422:
        if (res.message) {
          Notification.error({
            message: res.message
          })
        }
        return Promise.reject(new Error(res.message || 'Error'))
      default:
        return Promise.reject(error)
    }
  }
)

/**
 * 作者：TKE
 * 时间：2019/12/17 0017
 * 功能：对外接口
 * 描述：
 */
export default function request (options) {
  // 数据处理
  console.log(11111111111);
  const params = options.data || {}
  const method = options.method.toUpperCase()
  const url = options.url
  const responseType = options.responseType || 'json'
  const ContentType = options.ContentType || 'application/json;charset=UTF-8'
  //  'application/x-www-form-urlencoded;application/vnd.ms-excel;charset=UTF-8'
  if (method === 'GET') {
    return service({
      url,
      method,
      params,
      responseType
    })
  } else if (method === 'POST') {
     axios.defaults.headers.post['Content-Type'] = ContentType
    const data = params//QS.stringify(params)
    return service({
      url,
      method,
      data,
      responseType
    })
  } else if (method === 'PUT' || method === 'PATCH') {
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

    const data = QS.stringify(params)
    return service({
      url,
      method,
      data,
      responseType
    })
  } else if (method === 'DELETE') {
    return service({
      url,
      method,
      params,
      responseType
    })
  }
}

/**
 * 作者： TKE
 * 时间： 2019/12/17 0017
 * 功能：md5加密请求体
 * 描述：
 */
function CalcuMD5 (obj) {
  return new Promise(resolve => {
    // 如果不是对象  也就是说使用的GET和DELETE请求 那么不用加密  直接返回空
    const key = process.env.VUE_APP_AESKEY
    let sign = ''
    if (Object.prototype.toString.call(obj) !== '[object Object]') {
      sign = key + '--' + key
    } else {
      if (JSON.stringify(obj) === '{}') {
        sign = key + '--' + key
      } else {
        Object.keys(obj).sort().forEach((k) => {
          sign += key + k + '--' + obj[k] + key
        })
      }
    }
    // console.warn('签名==>'+sign);
    resolve(md5(sign).toUpperCase())
  })
}
