import Vue from 'vue'
import Vuex from 'vuex'
import {ConfigListAll} from '@/apis/peiZhi.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		screen_width: document.documentElement.clientWidth,//屏幕宽度
		configListAll: {},//配置信息
		menuListAll: [],//菜单列表
		yiJiMenu_path: '',//一级菜单当前路径
		erJiMenu_path: ''//二级菜单当前路径
	},
	getters: {
		getMenuItem: (state) => (name) => {
			console.log("name: ",name);
			for (var i = 0; i < state.menuListAll.length; i++) {
				console.log("state.menuListAll[i].name: ",state.menuListAll[i].name);
				if (state.menuListAll[i].name == name) {
					return state.menuListAll[i]
				} else {
					for (var j = 0; j < state.menuListAll[i].children.length; j++) {
						console.log("state.menuListAll[i].children[j].name: ",state.menuListAll[i].children[j].name);
						if (state.menuListAll[i].children[j].name == name) {
							return {...state.menuListAll[i].children[j],parent: state.menuListAll[i]}
						} else if(state.menuListAll[i].children[j].children) {
							for (var k = 0; k < state.menuListAll[i].children[j].children.length; k++) {
								console.log("state.menuListAll[i].children[j].children[k].name: ",state.menuListAll[i].children[j].children[k].name);
								if (state.menuListAll[i].children[j].children[k].name == name) {
									return {...state.menuListAll[i].children[j].children[k], parent : {...state.menuListAll[i].children[j], parent: {...state.menuListAll[i]}}}
								}
							}
						}
					}
				}
			}
			return {}
		},
		isMobile(state) {
			return state.screen_width <= 760 ? true:false
		},
		getHeaderHeight(state,getters) {
			if (getters.isMobile) {
				return '57px'
			} else {
				return '90px'
			}
		}
	},
	mutations: {
		changeScreenWidth(state,data) {
			var w = document.documentElement.clientWidth;
			var h = document.documentElement.clientHeight;
			localStorage.setItem('screen_width',w)
			state.screen_width = w
			console.log("changeScreenWidth: ",w);
		},
		changeConfigListAll(state,data) {
			localStorage.setItem('configListAll',data)
			state.configListAll = data
			// 动态设置网站标题和图标
			document.title = data.website_name
			document.querySelector('#favicon').href= `${process.env.VUE_APP_IMG_ROOT}` + data.website_logo
			console.log("changeConfigListAll: ",data);
		},
		changeMenuListAll(state,data) {
			localStorage.setItem('menuListAll',data)
			state.menuListAll = data
			console.log("changeMenuListAll: ",data);
		},
		changeYiJiMenuPath(state,data) {
			localStorage.setItem('yiJiMenu_path',data)
			state.yiJiMenu_path = data
			console.log("changeYiJiMenuPath: ",data);
		},
		changeErJiMenuPath(state,data) {
			localStorage.setItem('erJiMenu_path',data)
			state.erJiMenu_path = data
			console.log("changeErJiMenuPath: ",data);
		},
	},
	actions: {
		changeConfigListAllAction({commit}) {
			ConfigListAll().then(res => {
				commit('changeConfigListAll',res.data)
			})
		}
	},
	modules: {}
})
