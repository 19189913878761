import { render, staticRenderFns } from "./App.vue?vue&type=template&id=638b7d11&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=638b7d11&prod&lang=scss"
import style1 from "./App.vue?vue&type=style&index=1&id=638b7d11&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638b7d11",
  null
  
)

export default component.exports